const Modal = ({ children }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">{children}</div>
      <style jsx>{`
        .modal-overlay {
          position: fixed;
          height: 100vh;
          width: 100vw;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          width: 100%;
          height: 100%;
        }
      `}</style>
    </div>
  );
};

export default Modal;
