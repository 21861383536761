'use client';

import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie for cookie handling
import setAuthToken from '@/utils/setAuthToken';

const AuthContext = createContext({
	user: null,
	isAuthenticated: false,
	loading: true,
	LOAD_USER: () => {},
	REGISTER_SUCCESS: () => {},
	REGISTER_FAIL: () => {},
	LOGIN_SUCCESS: () => {},
	LOGIN_FAIL: () => {},
	LOGOUT: () => {},
	USER_LOADING: () => {},
	USER_LOADED: () => {},
	USER_ERROR: () => {},
	AUTH_ERROR: () => {},
	DELETE_USER: () => {},
});

export const AuthWrapper = ({ children }) => {
	const [auth, setAuth] = useState({
		user: null,
		isAuthenticated: false,
		loading: true,
	});

	const LOAD_USER = async () => {
		const token = Cookies.get('token'); // Get token from cookies

		if (!token) {
			setAuth((prevState) => ({
				...prevState,
				loading: false,
			}));
			return;
		}

		setAuth((prevState) => ({
			...prevState,
			loading: true,
		}));

		try {
			const res = await axios.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth`,
				{
					headers: {
						'x-auth-token': token,
					},
				}
			);

			setAuth((prevState) => ({
				...prevState,
				user: res.data,
				isAuthenticated: true,
				loading: false,
			}));
		} catch (err) {
			console.log(err);
			setAuth((prevState) => ({
				...prevState,
				loading: false,
			}));
		}
	};

	const DELETE_USER = async (props) => {
		setAuth((prevState) => ({
			...prevState,
			loading: true,
		}));

		await axios
			.delete(`${process.env.NEXT_PUBLIC_SERVER_API}/api/users/${props}`)
			.then(async (res) => {
				Cookies.remove('token'); // Remove token from cookies
				delete axios.defaults.headers.common['x-auth-token'];

				setAuth((prevState) => ({
					token: null,
					isAuthenticated: false,
					loading: false,
					user: null,
				}));

				SET_ALERT({
					msg: `Your account has been deleted with all it's listings!`,
					type: 'success',
				});
			})
			.catch((error) => {
				setAuth((prevState) => ({
					...prevState,
					loading: false,
				}));

				SET_ALERT({
					msg: `Error deleting account!`,
					type: 'danger',
				});
			});
	};

	const REGISTER_SUCCESS = async ({ token, user }) => {
		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			global?.window &&
			window.analytics?.identify(user._id, {
				firstName: user.firstName,
				lastName: user.lastName ? user.lastName : '',
				email: user.email,
				phone: user.phone ? user.phone : '',
			});

		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			global?.window &&
			window.analytics?.track('sign_up', {
				method: user.OAuth ? user.OAuth : 'email',
			});

		Cookies.set('token', token, { expires: 7 }); // Set token in cookies (expires in 7 days)

		setAuthToken(token);

		setAuth((prevState) => ({
			isAuthenticated: true,
			loading: false,
			token,
			user,
		}));
	};

	const REGISTER_FAIL = async () => {
		Cookies.remove('token'); // Remove token from cookies
		delete axios.defaults.headers.common['x-auth-token'];

		setAuth((prevState) => ({
			token: null,
			isAuthenticated: false,
			loading: false,
		}));
	};

	const LOGIN_SUCCESS = async ({ user, token }) => {
		global?.window &&
			window.analytics?.identify(user._id, {
				firstName: user.firstName,
				lastName: user.lastName ? user.lastName : '',
				email: user.email,
				phone: user.phone ? user.phone : '',
			});

		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
			global?.window &&
			window.analytics?.track('login', {
				method: user.OAuth ? user.OAuth : 'email',
			});

		Cookies.set('token', token, { expires: 7 }); // Set token in cookies

		setAuthToken(token);

		setAuth((prevState) => ({
			isAuthenticated: true,
			loading: false,
			token,
			user,
		}));
	};

	const LOGIN_FAIL = async () => {
		Cookies.remove('token'); // Remove token from cookies
		delete axios.defaults.headers.common['x-auth-token'];

		setAuth((prevState) => ({
			token: null,
			isAuthenticated: false,
			loading: false,
		}));
	};

	const LOGOUT = async () => {
		Cookies.remove('token'); // Remove token from cookies
		delete axios.defaults.headers.common['x-auth-token'];

		setAuth((prevState) => ({
			token: null,
			isAuthenticated: false,
			loading: false,
		}));
	};

	const USER_LOADING = async () => {
		setAuth((prevState) => ({
			...prevState,
			loading: true,
		}));
	};

	const USER_LOADED = async (user) => {
		setAuth((prevState) => ({
			user,
			isAuthenticated: true,
			loading: false,
		}));
	};

	const USER_ERROR = async () => {
		setAuth((prevState) => ({
			...prevState,
			isAuthenticated: true,
			loading: false,
		}));
	};

	const AUTH_ERROR = async () => {
		Cookies.remove('token'); // Remove token from cookies
		setAuth((prevState) => ({
			...prevState,
			isAuthenticated: true,
			loading: false,
		}));
	};

	useEffect(() => {
		LOAD_USER();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
				LOAD_USER,
				REGISTER_SUCCESS,
				REGISTER_FAIL,
				LOGIN_SUCCESS,
				LOGIN_FAIL,
				LOGOUT,
				USER_LOADING,
				USER_LOADED,
				USER_ERROR,
				AUTH_ERROR,
				DELETE_USER,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export function useAuthContext() {
	return useContext(AuthContext);
}
